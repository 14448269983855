import instance from './axios';

const axiosInstance = instance;

/**
 * Contact us
 * @param {Object} data the form data
 */
export const contactUs = async (data) => {
    try {
        const result = await axiosInstance.post('/contact-us', data);        
        return ({ success: result.status===200 , message: result.data?.message});
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}