import React from 'react';
import { Link } from 'react-router-dom';
import SVGIcons from '../../assets/images/icons/svgIcons';
import AppIcons from '../../assets/images/icons';

import './header.scss';
import BurgerMenu from '../nav-section/burger';

export default function AppHeader() {
    return (
        <>
            <div className='main-head-wrp f-wrp'>
                <div className='container'>
                    <div className='main-header f-wrp'>
                        <Link to={'/'} className='main-logo'><img src={AppIcons.logo} alt='Logo' /></Link>

                        <div className='burger-prof-sec'>
                            <div className='language-box'>
                                <Link to={'/'}>{SVGIcons.GlobalIcon()}</Link>
                            </div>
                            <div className='f-wrp'>
                                <div className='menu-box'>
                                    <BurgerMenu />
                                </div>
                                <div className='profile-sec-box'>
                                    <Link to={'/'}>{SVGIcons.ProfileIcon()}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}