import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import Marquee from "react-fast-marquee";
import AppLayout from '../../../layouts/app/AppLayout';
import AppIcons from './images/icons';
import Services from './Services/Services';
import SVGIcons from './images/icons/svgIcons';
import './custom.scss';
import Gallery from './Gallery/gallery';
import Testimonial from './testimonial/testimonial';
import AppHeader from './header';
import AppFooter from './footer';




const AngelicaTemplate = () => {

    const [height, setHeight] = useState(0);
    const elementRef = useRef(null);
    useEffect(() => {
        setHeight(elementRef.current.clientHeight);
    }, []);



    const programItems = [
        {
            icon: AppIcons.days,
            title: 'Days',
            description: <p>Open from Monday to Friday</p>,
        },
        {
            icon: AppIcons.hours,
            title: 'Hours',
            description: <p>From 6:30am to 5:00pm</p>,
        },
        {
            icon: AppIcons.ages,
            title: 'Accepted Ages',
            description: <p>We accept up to 9 children From 6 weeks to 12 years old</p>,
        },
        {
            icon: AppIcons.programs,
            title: 'Subsidy Programs',
            description: <><p>C4K <i>Care 4 Kids</i> <br />DCF <i>Children & Family Department</i> <br />CACFP <i>Child and Adult Care Food Program</i> <br />EHSCCP <i>Early Head Start</i></p></>,
        },
    ];

    const learningItems = [
        {
            icon: AppIcons.learning1,
            className: 'yellow',
            title: 'Physical Development \nand Health',
            description: <><ul><li>Develop Gross Motor Skills</li>
                <li>Develop Fine Motor Skills</li>
                <li>Acquire Adaptive Skills</li>
                <li>Maintain Physical Health and Well-Being</li>
                <li>Safety and Responsibility</li>
                <li>Physical Health Status</li>
                <li>Visual Motor Integration</li>
                <li>Mobility</li>
                <li>Safety and Responsibility</li>
            </ul></>,
        },
        {
            icon: AppIcons.learning2,
            className: 'Turquoise',
            title: 'Social and Emotional \nDevelopment',
            description: <><ul><li>Develop Self-Regulation</li>
                <li>Develop Trusting, Healthy Attachments and Relationships with Primary Caregivers</li>
                <li>Develop, Express, Recognize, and Respond to Emotions</li>
                <li>Develop Self-Awareness, Self-Concept, and Competence</li>
                <li>Develop Social Relationships</li>
                <li>Adult relationships</li>
                <li>Personal Preferences</li>
            </ul></>,
        },
        {
            icon: AppIcons.learning3,
            className: 'Green',
            title: 'Science',
            description: <>
                <ul><li>Apply Scientific Practices</li>
                    <li>Engage in the Process of Engineering</li>
                    <li>Understand Patterns, Process, and Relationships of Living Things</li>
                    <li>Understand Physical Science</li>
                    <li>Understand Features of Earth</li>
                    <li>Questioning and Defining Problems</li>
                    <li>Unity and Diversity of Life</li>
                    <li>Earth and Human Activity</li>

                </ul></>,
        },
        {
            icon: AppIcons.learning4,
            className: 'purple',
            title: 'Mathmatics',
            description: <>
                <ul><li>Understand Counting and Cardinality</li>
                    <li>Understand and Describe Relationships to Solve Problems (Operations and Algebraic Thinking)</li>
                    <li>Understand the Attributes and Relative Properties of Objects (Measurement and Data)</li>
                    <li>Understand Shapes and Spatial Relationships (Geometry and Spatial Sense)</li>

                </ul></>,
        },
        {
            hasValue: 'no',
            image: AppIcons.learning,
            className: ' hide'
        },
        {
            icon: AppIcons.learning5,
            className: 'Teal',
            title: 'Creative Arts',
            description: <>
                <ul><li>Engage In and Enjoy the Arts</li>
                    <li>Explore and Respond to Creative Works</li>
                    <li>Music</li>
                    <li>Visual Arts</li>
                    <li>Drama</li>
                    <li>Dance</li>

                </ul></>,
        },
        {
            icon: AppIcons.learning6,
            className: 'Orange',
            title: 'Cognition',
            description: <>
                <ul><li>Use Logic and Reasoning</li>
                    <li>Develop Eective Approaches to Learning</li>
                    <li>Strengthen Executive Functioning</li>
                    <li>Curiosity and Initiative</li>
                    <li>Eagerness to Learn</li>
                    <li>Cause and Eect</li>
                    <li>Problem Solving</li>
                    <li>Cognitive Flexibility</li>
                    <li>Working Memory</li>
                </ul></>,
        },
        {
            icon: AppIcons.learning7,
            className: 'Violet',
            title: 'Social Studies',
            description: <>
                <ul><li>Understand Change Over Time</li>
                    <li>Understand Self, Family, and a Diverse Community</li>
                    <li>Learn About People and the Environment</li>
                    <li>Develop an Understanding of Economic Systems and Resources</li>
                    <li>Culture</li>
                    <li>Individual Development and Identity</li>
                    <li>Individuals, Groups, and Institutions</li>

                </ul></>,
        },
        {
            icon: AppIcons.learning8,
            className: 'red',
            title: 'Language & \nLiteracy',
            description: <>
                <ul>
                    <li>Understand Language</li>
                    <li>Use Language</li>
                    <li>Use Language for Social Interaction</li>
                    <li>Gain Book Appreciation and Knowledge</li>
                    <li>Gain Knowledge of Print and its Uses</li>
                    <li>Develop Phonological Awareness</li>
                    <li>Convey meaning through drawing, letters, and words</li>
                    <li>Language Comprehension</li>
                </ul></>,
        },
    ];




    // custom home functions here
    const pageName = "Angelica's";
    return (
        <AppLayout
            pageName={pageName}
            bodyClassName='angelicaTemplates'
            customHeader={<AppHeader />}
            customFooter={<AppFooter />}
        >

            <div className="main-content-wrapper f-wrp">

                <div className='main-banner-wrp f-wrp'>
                    <div className='bannerBG'>
                        <span className='pcBG'><img src={AppIcons.bannerBG} alt='banner' /></span>
                        <span className='mobBG'><img src={AppIcons.bannerBGMob} alt='banner' /></span>

                    </div>
                    <div className='container'>
                        <div className='banner-align f-wrp'>
                            <div className='banner-con-sec f-wrp'>
                                <div className='banner-con-box f-wrp'>
                                    <span className='pc-icon'><img src={AppIcons.bannerLogo} alt='banner icon' /></span>
                                    <span className='mob-icon'><img src={AppIcons.logo} alt='banner icon' /></span>
                                    <div className='banner-con'>
                                        <h1>Nurturing <br />Young Minds</h1>
                                        <p>We offer an environment where children are given opportunities to learn and grow through intentional play according to age and needs.</p>
                                    </div>
                                </div>
                                <Button onClick={() => {
                                    window.location.href = '/'
                                }} className='sqr-btn'>Paren’ts Portal</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='service-slider-sec f-wrp'>
                    <Services />
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='about-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='about-grid-wrp f-wrp'>
                            <div className='about-con-sec'>
                                <h1>About <img src={AppIcons.smartKids} alt='smart kids' /></h1>
                                <p>I hold a <b>CDA Education Credential</b>, a <b>Diploma in Emotional Intelligence and Positive Psychology</b>, in addition to other professional studies, I also continue to stay updated in my educational journey.</p>
                                <p>I have been working as a childcare provider <b>since 2015, holding a license from the State of Connecticut Early Childhood Education.</b> In addition, I have been part of TEAM INC's Early Head Start program, which imparts valuable training on how to effectively teach children, along with professional support from health & nutrition specialists, social workers and education coordinators.</p>
                                <p>I assist children and families with all the necessary resources and information. You can expect <b>100% transparency</b> without hidden stories or unpleasant surprises.</p>
                                <span>Angélica</span>
                            </div>
                            <div className='about-img-sec'>
                                <span><img src={AppIcons.aboutImg} alt='children playing' /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='contact-blk-wrp f-wrp'>
                    <div className='contact-sec-blk f-wrp'>
                        <div className='contact-con'>
                            <div className='f-wrp'>
                                <h1>Get in touch</h1>
                                <h4>Have questions or ready to enroll? </h4>
                                <p>Reach out to us for more information about our nurturing childcare programs. We're here to support you and your child's journey. Contact us at</p>
                                <ul>
                                    <li>
                                        <div className='each-contact-block f-wrp'>
                                            <span className='contact-icon'><img src={AppIcons.mapPoint} alt='pin point' /></span>
                                            <p>22 Clinton St Waterbury, CT 06710</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='each-contact-block f-wrp'>
                                            <span className='contact-icon'><img src={AppIcons.mail} alt='mail' /></span>
                                            <p>smartkids122415@gmail.com</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='each-contact-block f-wrp'>
                                            <span className='contact-icon'><img src={AppIcons.phone} alt='call' /></span>
                                            <p>1 (203) 558-8172</p>
                                        </div>
                                    </li>
                                </ul>
                                <Button onClick={() => {
                                    window.location.href = '/'
                                }} className='sqr-btn'>Contact us</Button>
                            </div>
                        </div>
                        <div className='contact-img'>
                            <span><img src={AppIcons.contact} alt='child' /></span>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='program-sec-wrp f-wrp'>
                    <div className='program-sec-header f-wrp'>
                        <div className='container'>
                            <h1>PROGRAMS</h1>
                            <p>Here is important information about our facility</p>
                        </div>
                    </div>
                    <div className='gap f-wrp' style={{ paddingTop: '30px' }} />
                    <div className='program-con-wrp f-wrp'>
                        <div className='container'>
                            <div className='program-grid'>
                                {programItems.map((element, key) => (
                                    <div className='each-program-sec f-wrp' key={key}>
                                        <span className='program-icon'><img src={element.icon} alt={element.title} /></span>
                                        <h3>{element.title}</h3>
                                        {element.description}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='learning-sec-wrp f-wrp'>
                    <div className='container'>
                        <div className='learning-sec-header f-wrp'>
                            <h1>EARLY LEARNING</h1>
                            <p>Across all ages and domains, early learning experiences will support children to be creative, inquisitive, flexible, purposeful and reflective.</p>
                        </div>
                        <div className='learning-con-wrp f-wrp'>
                            <div className='learning-grid'>
                                <div className="row">
                                    {learningItems.map((element, key) => (
                                        <div className={`${element.className} col-lg-4 col-md-6 col-sm-12 col-xs-12 `} key={key}>
                                            {element.hasValue ? <div className={`${element.className} each-learning-sec f-wrp `}>
                                                <span className='learning-img'><img src={element.image} alt={element.title} /></span>
                                            </div> : <div className={`${element.className} each-learning-sec f-wrp `}>
                                                <div className='learing-header f-wrp'>
                                                    <h3>{element.title}</h3>
                                                </div>
                                                <div className='learing-content f-wrp'>
                                                    <span className='learning-icon'><img src={element.icon} alt={element.title} /></span>
                                                    {element.description}
                                                </div>
                                            </div>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


                <div className='activities-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='activities-grid-wrp f-wrp'>
                            <div className='activities-img-sec'>
                                <span><img src={AppIcons.hotBallon} alt='Hot Ballon' /></span>
                            </div>
                            <div className='activities-con-sec'>
                                <h1>Activities </h1>
                                <p>From creative arts to enriching language, we have something for every curious mind. Join us on a journey of discovery!</p>
                                <ul>
                                    <li><span>{SVGIcons.Act1Icon()}</span><p>Theme of the month</p></li>
                                    <li><span>{SVGIcons.Act2Icon()}</span><p>AB Patterns</p></li>
                                    <li><span>{SVGIcons.Act3Icon()}</span><p>Flash cards</p></li>
                                    <li><span>{SVGIcons.Act4Icon()}</span><p>Circle time</p></li>
                                    <li><span>{SVGIcons.Act5Icon()}</span><p>Colors & crafts</p></li>
                                    <li><span>{SVGIcons.Act6Icon()}</span><p>Holding pencil</p></li>
                                    <li><span>{SVGIcons.Act7Icon()}</span><p>Music, dance & songs</p></li>
                                    <li><span>{SVGIcons.Act8Icon()}</span><p>Sensory bins</p></li>
                                    <li><span>{SVGIcons.Act9Icon()}</span><p>Phonics and reading</p></li>
                                    <li><span>{SVGIcons.Act10Icon()}</span><p>Numbers and math concepts</p></li>
                                    <li><span>{SVGIcons.Act11Icon()}</span><p>Story time</p></li>
                                    <li><span>{SVGIcons.Act12Icon()}</span><p>Spelling and writing</p></li>
                                    <li><span>{SVGIcons.Act13Icon()}</span><p>Tidy after self</p></li>
                                    <li><span>{SVGIcons.Act14Icon()}</span><p>Toilet training</p></li>
                                </ul>
                            </div>


                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='gallery-section-wrp f-wrp'>
                    <div className='gallery-sec-header f-wrp'>
                        <h1>Gallery</h1>
                    </div>
                    <Gallery />

                    <div className='gap theme-band f-wrp' style={{ paddingTop: '75px' }} />
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='about-founder-wrp f-wrp'>
                    <div className='founder-sec-header f-wrp'>
                        <div className='container'>
                            <div className='gap f-wrp' style={{ paddingTop: '35px' }} />
                            <h1>Meet Our founder</h1>
                            <p>Get to know the soul behind Smart Kids:</p>

                            <div className='founder-con-wrp'>
                                <div className='founder-img'>
                                    <h4 style={{ opacity: '0' }}>hide</h4>
                                </div>
                                <div className='founder-con-blk' style={{ textAlign: 'left' }} ref={elementRef}>
                                    <p><b>Angelica C. Flores</b> was born in Mexico City, Mexico and immigrated to Waterbury, Connecticut in April 1999 with her husband and daughter. Since then, she has grown her family to include a son, made Waterbury her home, and even started her own family day care business.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='founder-con-sec f-wrp'>
                        <div className='container'>
                            <div className='founder-con-wrp'>
                                <div className='founder-img' style={{ marginTop: `${-height + 20}px` }}>
                                    <span><img src={AppIcons.founder} alt='founder' /></span>
                                    <h2>Angélica C. Flores</h2>
                                    <p>Founder</p>
                                </div>
                                <div className='founder-con-blk'>
                                    <p className='mob-para'><b>Angelica C. Flores</b> was born in Mexico City, Mexico and immigrated to Waterbury, Connecticut in April 1999 with her husband and daughter. Since then, she has grown her family to include a son, made Waterbury her home, and even started her own family day care business.</p>
                                    <p>She first started volunteering when she began taking classes at the Waterbury Library Literacy Program seven years ago. Once she mastered the language, she began assisting others with questions about various paperwork or applications they did not understand. She also registered them for English as a Second Language Classes. Her love of community continued to grow throughout the years. Angelica first became involved with Madre Latina Inc. when she attended one of the meetings out of curiosity three years ago. Madre Latina Inc. promotes and encourages the advancement of Hispanic mothers in their careers, business, and lives by providing them with technical training and mentorship. Since then, Angélica has become a mother of the organization and has been volunteering for a year organizing events and leading outreach efforts in the community.</p>
                                    <p>Her passion for her home country always remained. Angélica spearheaded Mexico's table at the The Gathering, Waterbury's multicultural event, since it began four years ago. She is vital in ensuring that the decorations, dress, artifacts, and other items on the table are organized and culturally significant. Often times she has even led Mexico's group through the parade route waving the Mexican flag or dancing in floral printed skirts. Dressed in colorful traditional garb, Angelica always impresses welcomes others to learn about Mexico and invites them to share in the experience.</p>
                                    <p>As a strong leader, Angélica is driven to making a difference for her family and community. She is proud to be a Mexican living in the United States. She strives to make Waterbury a place where diversity can be celebrated and cherished. She showcases to others everything that is special about Mexico and enjoys sharing her culture. She loves her new home and community. Angelica is passionate about inspiring others to get involved in their community. Though we all come from different parts of the world, she values the importance of coming together.</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='testimonial-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='testimonial-sec-header f-wrp'>
                            <h1>Reviews</h1>
                        </div>
                        <Testimonial />
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='scrolling-sec f-wrp'>

                    <Marquee
                        autoFill
                        direction={"left"}
                        speed={70}
                    >
                        <div style={{ padding: '0 75px' }}> No registration fees! </div> <div style={{ padding: '0 75px' }}> diapers · wipes · meals included in tuition! </div>
                    </Marquee>
                </div>

                <div className='pay-optiopn-wrp f-wrp'>
                    <div className='container'>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className='payment-content-sec f-wrp'>
                                    <h1>Payment Options</h1>
                                    <p>Payments Made Easy: We are pleased to announce we now offer the convenience of credit and debit card payments, as well as bank transfers through Stripe. </p>

                                    <p><b>We do not charge for registration fees!</b> Also diapers, wipes, and meals are all included in the tuition!</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">

                                <div className='payment-button-sec f-wrp'>
                                    <span>
                                        <img src={AppIcons.payment} alt='payment' />
                                    </span>
                                    <Button onClick={() => {
                                        window.location.href = '/'
                                    }} className='sqr-btn'>Pay online</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap theme-band f-wrp' style={{ paddingTop: '30px' }} />
           </div>
        </AppLayout>
    )
}

export default AngelicaTemplate;