import React from 'react';
import { Button } from '@mui/material';
import AppIcons from '../../assets/images/icons';
import AppLayout from '../../layouts/app/AppLayout';
import './custom.scss';





const HomePage = () => {

    // custom home functions here
    const pageName = "template list";
    return (
        <AppLayout
            pageName={pageName}
            bodyClassName='templateList'
        >

            <div className="template-content-wrapper f-wrp">
                <div className='bannerBG'>
                    <span className='pcBG'><img src={AppIcons.bannerBG} alt='banner' /></span>
                </div>
                
                <div className='templateList-wrp'>
                    <Button onClick={() => {
                        window.location.href = '/angelica'
                    }} className='sqr-btn'>Angelica’s Website</Button>

                    <Button onClick={() => {
                        window.location.href = '/kathleen'
                    }} className='sqr-btn'>kathleen’s Website</Button>
                </div>

            </div>
        </AppLayout>
    )
}

export default HomePage;