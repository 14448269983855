import React from "react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import './testimonial.scss';


function Testimonial() {


  const testimonialItems = [
    {
      name: 'Leidmaire S.',
      date: 'Aug 17',
      content: <><p>I have a lot to thank Angélica for, for her dedication and affection towards my son. Since he started attending Smart Kids Family Daycare he has had an incredible evolution, as he has a delay in development and speech. I recommend it. Thank you Angelica for everything.</p></>,
    },
    {
      name: 'Jacquie',
      date: 'Aug 16',
      content: <><p>Very professional and knowledgeable, Everyone becomes a big family the staff are great with the children, they love to be in the daycare. Support all families need and provide resources as needed. My children loves Ms Flowers</p></>,
    },
    {
      name: 'Nisheida Smalls',
      date: 'Aug 20',
      content: <><p>My Baby started here when he was a few weeks over one. Mrs Flores has Taught him sooooooo much and i am forever grateful for her. She accepted my son and rushed for him to start day care for me i was on a timed schedule and she helped me out. Within a month i seen a difference in my son he learned so many words in English and Spanish Thankkkk you Mrs Flores Ri’lee Loves you</p></>,
    },
    {
      name: 'Belky ',
      date: 'May 26, 2022',
      content: <><p>They are a very organized daycare and they take great care of my child. My daughter has learned a lot from this daycare</p></>,
    },
    {
      name: 'Lizz ',
      date: 'May 25, 2022',
      content: <><p>I love it!! My son loves it!!! Great care!! Great communication!!!!! I can be at peace knowing my son is under great supervision! He gets helped with his homework! And always has a great time!!! Will recommend to anybody!!!</p></>,
    },
    {
      name: 'Brenda ',
      date: 'Oct 09, 2021',
      content: <><p>Smart kids Daycare is a excellent daycare for children! The teacher Angelica is very sweet and has the best Interest in teaching all the kids. My child has been there for a year now and she has Improve so much with her speech and learning Ability. My child has learn a lot of Spanish which is great cause we at home are Bilingual. I highly recommend this daycare for families.</p></>,
    },
  ];

  return (
    <>
      <div className="testimonial-sec-wrp">
        <div className="f-wrp">
          <Swiper
            modules={[Navigation, Autoplay, Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            className='content-sec-mob'
            navigation
            loop
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              dynamicBullets: true,
            }}

          >
            {testimonialItems.map((element, key) => (
              <SwiperSlide key={key}>
                <div className="each-testimonial-items f-wrp">
                  {element.content}
                  <h2>{element.name} <span>· {element.date}</span></h2>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

        </div>
      </div>






    </>
  );
}

export default Testimonial;